.tableHeader {
  font-size: 20px;
  font-weight: bold;
}
.bottomButtons {
  display: flex;
  gap: 20px;
  justify-content: space-between;

  margin: 20px 0;
}
