.item {
  display: flex;
  gap: 20px;
  align-items: center;

  margin-bottom: 10px;
  padding: 5px;

  border-radius: 10px;

  img {
    width: 80px;
    height: 100px;

    object-fit: cover;
  }

  .details {
    h1 {
      font-size: 18px;
      font-weight: 500;
    }

    p {
      margin-bottom: 10px;

      font-size: 14px;
    }
  }
}
