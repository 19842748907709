.wrapper {
  position: fixed;
  top: 60px;

  display: flex;
  grid-area: sidebar;
  flex-direction: column;
  gap: 20px;

  width: 200px;
  //min-height: calc(100vh - 61px);
  height: 100%;
  padding: 20px;

  background-color: seashell;
}
