.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  picture {
    position: absolute;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    filter: brightness(40%);

    img {
      width: 100%;
      height: 100vh;

      object-fit: cover;
    }
  }
  h1 {
    margin-bottom: 20px;

    font-size: 50px;
    color: whitesmoke;
    text-align: center;
    text-transform: uppercase;
  }
}

.innerWrap {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;

  height: 400px;
}

/*****   Aside Side  *****/
.left {
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  height: 100%;

  color: white;
}
.buttonTab {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 100%;

  color: black;

  background-color: white;
  border-radius: 40px;

  .iconTab {
    margin-bottom: 10px;

    font-size: 40px;
  }
  span {
    color: inherit;
    text-transform: uppercase;
  }
}
.buttonActive {
  color: white;

  background-color: #00ad45;
}

/*****   ProfileMenu Side  *****/
.right {
  flex: 5;

  width: 400px;
  height: 100%;

  text-align: center;

  background-color: white;
}
.formWrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-around;

  height: 100%;
  padding: 20px 40px;

  input {
    width: 100%;
    padding: 10px 20px;

    font-family: FontAwesome, monospace;
    font-size: 20px;

    background-color: #eeeeee;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }

  p {
    a {
      text-decoration: none;
    }
  }
  input::placeholder {
    padding-left: 5px;
  }
  button:not([class]) {
    background-color: transparent;
    border: none;
  }
}
.buttonSubmit {
  cursor: pointer;

  padding: 10px 0;

  font-size: 20px;
  font-weight: bold;
  color: white;
  letter-spacing: 3px;

  background-color: #00ad45;
  border: none;
}
.buttonSubmit:hover {
  background-color: #00963b;

  transition: 0.2s;
}
.buttonSubmit:disabled {
  cursor: not-allowed;

  background-color: #99c4a9;
}
