.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 400px;
  padding: 10px;

  text-align: center;

  border: 1px solid #000;
  box-shadow: 0 0 49px 5px rgba(0, 0, 0, 0.5);
}
