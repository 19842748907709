.wrapper {
  padding: 30px 0;

  text-align: center;

  h1 {
    font-size: 30px;
  }
}

.inner {
  display: flex;
  gap: 20px;

  margin: 20px 0;

  text-align: left;

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;

    padding: 20px;

    img {
      overflow: hidden;

      height: 100%;

      object-fit: cover;
    }

    ul {
      padding-left: 20px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .inner {
    flex-direction: column;
    gap: 0;
  }
}
