.wrapper {
  display: flex;
  justify-content: space-between;

  form {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 1rem;
    }

    label {
      margin-bottom: 0.5rem;

      font-weight: 400;
    }
  }

  .deliveryImgBox {
    max-width: 400px;
    max-height: 300px;

    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
