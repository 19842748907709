.list {
  li {
    font-size: 18px;
    font-weight: 500;

    p {
      margin-top: 1rem;
      margin-bottom: 2.5rem;

      font-size: 15px;
      line-height: 1.75rem;
    }
  }
}
