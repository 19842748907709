.form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 20px;

  input {
    text-align: center;
  }
}
