.wrapper {
  h5 {
    margin-bottom: 5px;
  }

  p,
  ul {
    margin-bottom: 30px;
  }

  ul {
    padding-left: 20px;
  }
}
