.wrapper {
  width: 100%;
  height: 500px;

  text-align: center;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    height: 100%;
    margin: 20px 0;

    p {
      color: red;
    }
  }
}
