.nameFavo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imgBox {
  overflow: hidden;

  height: 300px;
  img {
    object-fit: cover;
  }
}
.CardContent {
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: space-between;

  height: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }
  p {
    overflow: hidden;
  }
}
