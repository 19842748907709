.wrapper {
  grid-area: footer;

  padding: 20px 0;
}
.inner {
  background-color: red;
}
.footerNav {
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
.footerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .footerBottom {
    flex-direction: column-reverse;
    gap: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .footerNav {
    flex-wrap: wrap;
    gap: 20px;

    div {
      flex: auto;
    }
  }
}
