.cart {
  display: flex;
  flex-direction: column;
  gap: 10px;

  max-width: 400px;
  padding: 20px;

  .emptyText {
    margin-bottom: 20px;

    text-align: center;
  }
  h1 {
    margin-bottom: 10px;

    font-size: 24px;
    font-weight: 400;
  }
}
.total {
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;

  font-size: 18px;
  font-weight: 500;
}
.reset {
  cursor: pointer;

  margin-top: 20px;

  font-size: 15px;
  color: #ec2f2f;
}
