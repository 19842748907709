.wrapper {
  h2 {
    margin: 20px 0;
  }
  .inner {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }
}

@media only screen and (max-width: 850px) {
  .inner {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
