.product {
  display: flex;
  gap: 50px;

  padding: 10px;

  .left {
    display: flex;
    flex: 1;
    gap: 20px;

    .images {
      flex: 1;

      img {
        cursor: pointer;

        height: 150px;
        margin-bottom: 10px;

        opacity: 0.5;
        object-fit: cover;
      }
    }
    .mainImg {
      flex: 5;

      img {
        max-height: 400px;

        object-fit: cover;
      }
    }
  }

  .right {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;

    .buttonsBox {
      display: flex;
      gap: 30px;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 930px) {
  .product {
    flex-direction: column;

    .right {
      align-items: center;
    }
  }
}

@media only screen and (max-width: 800px) {
  .images {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .product {
    flex-direction: column;
    gap: 10px;
  }
}
