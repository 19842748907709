.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  a {
    font-size: 30px;
  }

  img {
    max-width: 500px;
  }
}
