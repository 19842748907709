.wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }
  .inner {
    z-index: 2;

    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    margin: 30px 0;

    h1 {
      span {
        color: darkgoldenrod;
      }
    }

    div {
      display: flex;
      align-items: center;

      input {
        padding: 8px 10px;

        font-weight: bold;
        color: seagreen;
      }
      button {
        text-transform: initial;
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .inner {
    div {
      flex-direction: column;
      gap: 10px;
    }
  }
}
