.wrapper {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(241, 141, 45, 0.19);
}
.none {
  display: none;
}
